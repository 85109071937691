import React from 'react'
import { useNavigate } from 'react-router-dom'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ITeam } from '../../../utils/datatypes'

type Props = {
  teams: ITeam[] | []
  // eslint-disable-next-line no-unused-vars
  updateLoading: (state: boolean) => void
}

export default function TeamsTree({
  teams,
  updateLoading,
}: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleClick = (id: string): void => {
    updateLoading(true)
    navigate(`/list/${id}`)
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {teams.map((team) => (
        <TreeItem nodeId={team.id} label={team.name} key={team.id}>
          {team.spaces?.map((space) => (
            <TreeItem nodeId={space.id} label={space.name} key={space.id}>
              {space.folders?.map((folder) => (
                <TreeItem
                  nodeId={folder.id}
                  label={folder.name}
                  key={folder.id}
                >
                  {folder.lists?.map((list) => (
                    <TreeItem
                      nodeId={list.id}
                      label={list.name}
                      key={list.id}
                      onClick={() => handleClick(list.id)}
                    />
                  ))}
                </TreeItem>
              ))}
            </TreeItem>
          ))}
        </TreeItem>
      ))}
    </TreeView>
  )
}
