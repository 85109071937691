import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { getTasks } from '../services/clickupAPI'
import Chart from '../components/Chart/Chart'
import formatTasks from '../utils/tasks'
import { ChartTask, ContextType } from '../utils/datatypes'
import useClasses from '../hooks/useClasses'
import styles from './styles'

export default function List(): React.ReactElement {
  const { setLoading } = useOutletContext<ContextType>()
  const navigate = useNavigate()
  const classes = useClasses(styles)
  const apiKey = window.localStorage.getItem('apiKey')
  const { id } = useParams<'id'>()

  const [error, setError] = React.useState<{ message?: string } | null>()
  const [tasks, setTasks] = React.useState<ChartTask[]>([
    {
      id: '',
      name: '',
      start: new Date().toISOString().split('T')[0],
      end: '',
      progress: 0,
      dependencies: '',
    },
  ])

  React.useEffect(() => {
    if (!apiKey) {
      navigate('register-key')
    } else {
      getTasks(apiKey, id).then((res) => {
        if (!res.error.success) {
          setError(res.error)
        } else {
          setTasks(formatTasks(res.data.tasks))
        }
        setLoading(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="start" alignItems="start">
        <Grid item xs={12}>
          <Chart tasks={tasks} />
        </Grid>
        <Grid item>
          <code>{error?.message}</code>
        </Grid>
      </Grid>
    </div>
  )
}
