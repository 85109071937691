import { Dependency, ITask, ChartTask } from './datatypes'

export const getCustomClass = (statusType: string): string => {
  switch (statusType) {
    case 'delayed':
      return 'delayed'
    case 'in progress':
      return 'in-progress'
    case 'refactor':
      return 'refactor'
    case 'unit test':
      return 'unit-test'
    case 'review':
      return 'review'
    default:
      return 'open'
  }
}

export const formatDate = (date: string): string => {
  const newDate = new Date(Number(date))
  return newDate.toISOString().split('T')[0]
}

export const getDependencies = (task: ITask): string => {
  let dependencies = ''
  if (task.dependencies) {
    task.dependencies.forEach((obj: Dependency) => {
      if (obj.task_id === task.id || obj.depends_on !== task.id) {
        dependencies += `${obj.depends_on}, `
      }
    })
  }
  return dependencies
}

export default function formatTasks(tasks: ITask[]): ChartTask[] {
  const newTasks: Array<ChartTask> = []
  for (let i = 0; i < tasks.length; i += 1) {
    let startDate = tasks[i].start_date
    if (startDate === null) {
      startDate = tasks[i].date_created
    }
    const newTask = {
      id: tasks[i].id,
      name: tasks[i].name,
      originalName: tasks[i].originalName,
      start: formatDate(startDate),
      end: formatDate(tasks[i].due_date),
      progress: 0,
      dependencies: getDependencies(tasks[i]),
      custom_class: getCustomClass(tasks[i].status.status),
      assignees: tasks[i].assignees,
      description: tasks[i].description,
      due_date: tasks[i].due_date,
      space: tasks[i].space,
      folder: tasks[i].folder,
      list: tasks[i].list,
      project: tasks[i].project,
      status: tasks[i].status,
      tags: tasks[i].tags,
      url: tasks[i].url,
    }
    newTasks.push(newTask)
  }
  return newTasks
}
