/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { css } from '@emotion/css'
import { useTheme } from '@emotion/react'

const useClasses = (stylesElement: any): any => {
  const theme = useTheme()
  return useMemo(() => {
    const rawClasses =
      typeof stylesElement === 'function' ? stylesElement(theme) : stylesElement

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.entries(rawClasses).forEach(([key, value = {}]: any) => {
      rawClasses[key] = css(value)
    })

    return rawClasses
  }, [stylesElement, theme])
}

export default useClasses
