// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles = (): any => ({
  root: {
    maxWidth: '90vw',
  },
  tasksList: {
    maxWidth: '10vw',
  },
})

export default styles
