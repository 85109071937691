import React from 'react'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Chart from '../components/Chart/Chart'
import { useMembersContext } from '../context/global-members-context'
import formatTasks from '../utils/tasks'
import { ChartTask, ContextType } from '../utils/datatypes'
import { getTasksForMember } from '../services/clickupAPI'
import useClasses from '../hooks/useClasses'
import styles from './styles'

export default function List(): React.ReactElement {
  const { setLoading } = useOutletContext<ContextType>()
  const navigate = useNavigate()
  const classes = useClasses(styles)
  const { teamId } = useParams<'teamId'>()
  const { memberId } = useParams<'memberId'>()
  const apiKey = window.localStorage.getItem('apiKey')
  const { members } = useMembersContext()

  const [error, setError] = React.useState<{ message?: string } | null>()
  const [tasks, setTasks] = React.useState<ChartTask[]>([
    {
      id: '',
      name: '',
      start: new Date().toISOString().split('T')[0],
      end: '',
      progress: 0,
      dependencies: '',
    },
  ])

  React.useEffect(() => {
    if (!apiKey) {
      navigate('register-key')
    } else if (members && teamId && memberId) {
      const localMember = members[teamId]
        ? members[teamId][memberId]
        : { lists: [] }
      if (localMember) {
        const listsIds = localMember.lists.map((list) => list.id)
        getTasksForMember(apiKey, listsIds, memberId).then((res) => {
          if (!res.error.success) {
            setError(res.error)
          } else {
            setTasks(formatTasks(res.data.tasks))
          }
          setLoading(false)
        })
      }
    } else {
      setTasks([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members, memberId, teamId])

  return (
    <div className={classes.root}>
      <Grid container direction="row" justifyContent="start" alignItems="start">
        <Grid item xs={12}>
          {tasks?.length ? (
            <Chart tasks={tasks} />
          ) : (
            <Typography variant="h5">No tasks found</Typography>
          )}
        </Grid>
        <Grid item>
          <code>{error?.message}</code>
        </Grid>
      </Grid>
    </div>
  )
}
