import axios, { AxiosInstance } from 'axios'
import { IApiTeam, IApiSpace, IApiTasks } from '../../utils/datatypes'

export const clickupBaseUrl = process.env.REACT_APP_API_URL

const instance = axios.create({
  baseURL: clickupBaseUrl,
  timeout: 10000,
})

instance.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error),
)

const api = (apiKey: string): AxiosInstance => {
  const authorization = 'Authorization'
  instance.defaults.headers.common[authorization] = apiKey
  return instance
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchData = (url: string, key: string): Promise<any> => {
  return fetch(`${clickupBaseUrl}${url}`, {
    method: 'GET',
    headers: {
      Authorization: key,
    },
  })
}

export const fetchTeam = (url: string, key: string): Promise<IApiTeam> => {
  return fetchData(url, key).then((res) => res.json())
}

export const fetchSpaces = (url: string, key: string): Promise<IApiSpace> => {
  return fetchData(url, key).then((res) => res.json())
}

export const fetchTasks = (url: string, key: string): Promise<IApiTasks> => {
  return fetchData(url, key).then((res) => res.json())
}

export default api
