import * as React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import AvatarGroup from '@mui/material/AvatarGroup'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { ChartTask } from '../../utils/datatypes'
import { ColoredAvatar, ColoredChip, Transition } from './styled'

type Props = {
  open: boolean
  task: ChartTask | undefined
  handleClose: () => void
}

export default function TaskDialog({
  open,
  task,
  handleClose,
}: Props): React.ReactElement {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box py={1}>
          <Typography variant="caption">
            {`${task?.folder?.name} / ${task?.list?.name}`}
          </Typography>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">{task?.originalName}</Typography>
          </Grid>
          <Grid item>
            <Box mr={0.5}>
              <AvatarGroup>
                {task?.assignees?.map((assignee) => (
                  <ColoredAvatar color={assignee.color} key={assignee.username}>
                    {assignee.initials}
                  </ColoredAvatar>
                ))}
              </AvatarGroup>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid item>
            <ColoredChip
              bgColor={task?.status?.color}
              label={task?.status?.status}
            />
          </Grid>
          <Grid item>
            <ColoredChip
              bgColor="#959595"
              label={`starts: ${task?.start}, ends: ${task?.end}`}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="caption">
            {task?.tags?.map((tag) => (
              <ColoredChip
                key={tag.tag}
                bgColor={tag.tag_bg}
                label={tag.name}
              />
            ))}
          </Typography>
          <Box my={1}>
            <Typography
              variant="body1"
              align={task?.description ? 'left' : 'center'}
            >
              {task?.description ? task.description : 'No description'}
            </Typography>
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
