import React from 'react'
import Typography from '@mui/material/Typography'

export default function Dashboard(): React.ReactElement {
  return (
    <Typography variant="h5" align="center" color="primary">
      Automita - Gantt Chart
    </Typography>
  )
}
