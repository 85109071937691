/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import api, {
  fetchData,
  fetchTeam,
  fetchSpaces,
  fetchTasks,
} from './api.service'
import { ITeam, ISpace, ITask } from '../../utils/datatypes'

export interface ITeamsResponse {
  success: boolean
  error: {
    message?: string
  }
}

interface ICallBackTeams {
  // eslint-disable-next-line no-unused-vars
  (teamData: ITeam): void
}

export const getTeams = async (
  apiKey: string,
  callBack: ICallBackTeams,
): Promise<ITeamsResponse> => {
  try {
    fetchTeam('team', apiKey).then((teamsRes) => {
      teamsRes.teams.forEach((team: ITeam) => {
        fetchSpaces(`team/${team.id}/space`, apiKey).then((spacesRes) => {
          const urls = spacesRes.spaces.map(
            (space: ISpace) => `space/${space.id}/folder`,
          )
          Promise.all(
            urls.map((url: string) => {
              return fetchData(url, apiKey)
            }),
          )
            .then((res) => Promise.all(res.map((r) => r.json())))
            .then((foldersRes) => {
              const data = {
                id: team.id,
                name: team.name,
                color: team.color,
                members: team.members.map((member: any) => ({
                  id: member.user.id,
                  username: member.user.username,
                  color: member.user.color,
                  initials: member.user.initials,
                  date_joined: member.user.date_joined,
                  date_invited: member.user.date_invited,
                })),
                spaces: spacesRes.spaces.map((space: ISpace, index) => ({
                  id: space.id,
                  name: space.name,
                  members: space.members?.map((member: any) => ({
                    id: member.user.id,
                    username: member.user.username,
                    color: member.user.color,
                    initials: member.user.initials,
                    date_joined: member.user.date_joined,
                    date_invited: member.user.date_invited,
                  })),
                  folders: foldersRes[index].folders,
                })),
              }
              callBack(data)
            })
        })
      })
    })
    return { success: true, error: {} }
  } catch (err: unknown) {
    const error = Object(err)
    return { success: false, error }
  }
}

export interface IApiResponse {
  data: { tasks: ITask[] } | any
  error: {
    message?: string
    success: boolean
  }
}

export const getTasks = async (
  apiKey: string,
  listId: string | undefined,
): Promise<IApiResponse> => {
  try {
    const data: { tasks: ITask[] } = await api(apiKey).get(
      `list/${listId}/task?archived=false&order_by=due_date&reverse=true`,
    )
    let tasks: ITask[] = []
    tasks = data.tasks.filter((task: ITask) => task.due_date !== null)
    tasks.forEach((task: ITask) => {
      const assignees: string[] = task.assignees.map(
        (assignee) => assignee.username,
      )
      task.originalName = task.name
      task.name = `${task.name}: ${assignees.join(', ')}`
    })
    return { data: { tasks }, error: { success: true } }
  } catch (err: unknown) {
    const error = { ...Object(err), success: false }
    return { data: {}, error }
  }
}

export const getTasksForMember = async (
  apiKey: string,
  listsIds: string[],
  memberId: string | undefined,
): Promise<IApiResponse> => {
  try {
    const urls = listsIds.map(
      (listId: string) =>
        `list/${listId}/task?archived=false&assignees%5B%5D=${memberId}&order_by=due_date&reverse=true`,
    )
    const data = await Promise.all(urls.map((url) => fetchTasks(url, apiKey)))
    let tasks: ITask[] = []
    data.forEach((el) => {
      tasks = [...tasks, ...el.tasks]
    })
    tasks = tasks.filter((task: ITask) => task.due_date !== null)
    tasks.forEach((task: ITask) => {
      const assignees: string[] = task.assignees.map(
        (assignee) => assignee.username,
      )
      task.originalName = task.name
      task.name = `${task.name}: ${assignees.join(', ')}`
    })
    return { data: { tasks }, error: { success: true } }
  } catch (err: unknown) {
    const error = { ...Object(err), success: false }
    return { data: {}, error }
  }
}
