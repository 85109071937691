import React from 'react'
import { ITeamMember } from '../utils/datatypes'

type IMembersContext = {
  members: ITeamMember
  // eslint-disable-next-line no-unused-vars
  setMembers: (member: ITeamMember) => void
}

const MemberContext = React.createContext<IMembersContext>({
  members: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMembers: () => {},
})

export const useMembersContext = (): IMembersContext =>
  React.useContext(MemberContext)

type IMembersContextProviderProps = {
  children: React.ReactNode
}

export function MembersProvider({
  children,
}: IMembersContextProviderProps): React.ReactElement {
  const [members, setMembers] = React.useState<ITeamMember>({})
  return (
    <MemberContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ members, setMembers }}
    >
      {children}
    </MemberContext.Provider>
  )
}
