import React from 'react'
import { useNavigate } from 'react-router-dom'
import TreeView from '@mui/lab/TreeView'
import TreeItem from '@mui/lab/TreeItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { ITeamMember } from '../../../utils/datatypes'

type Props = {
  teams: ITeamMember
  // eslint-disable-next-line no-unused-vars
  updateLoading: (state: boolean) => void
}

export default function MembersTree({
  teams,
  updateLoading,
}: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleClick = (teamId: string, memberId: string): void => {
    updateLoading(true)
    navigate(`/team/${teamId}/member/${memberId}`)
  }
  const getSortedMembers = (id: string): string[] => {
    const membersArr: string[] = []

    Object.keys(teams[id]).map((memberId) =>
      membersArr.push(
        `${teams[id][memberId].date_invited}&${teams[id][memberId].id}`,
      ),
    )
    membersArr.sort((a, b) => a.localeCompare(b))

    return membersArr
  }

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {Object.keys(teams).map((id) => {
        const team = teams[id]
        return (
          <TreeItem nodeId={id} label={id.split('$')[0]} key={id}>
            {getSortedMembers(id).map((memberName) => {
              const memberId = memberName.split('&')[1]
              const member = team[memberId]
              return (
                <TreeItem
                  nodeId={memberId}
                  label={member.username}
                  key={memberId}
                  onClick={() => handleClick(id, memberId)}
                />
              )
            })}
          </TreeItem>
        )
      })}
    </TreeView>
  )
}
