import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Drawer, Divider, IconButton } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { DrawerHeader, drawerWidth } from './styled'
import { getTeams } from '../../../services/clickupAPI'
import { ITeam, ITeamMember } from '../../../utils/datatypes'
import SwitchBtn from './SwitchBtn'
import TeamsTree from './TeamsTree'
import MembersTree from './MembersTree'
import { useMembersContext } from '../../../context/global-members-context'

type Props = {
  open: boolean
  loading: boolean
  handleDrawerClose: () => void
  // eslint-disable-next-line no-unused-vars
  updateLoading: (state: boolean) => void
}

export default function NavList({
  open,
  loading,
  handleDrawerClose,
  updateLoading,
}: Props): React.ReactElement {
  const navigate = useNavigate()
  const apiKey = window.localStorage.getItem('apiKey')
  const [teams, setTeams] = React.useState<ITeam[] | []>([] as ITeam[])
  const [toggleList, setToggleList] = React.useState(true)
  const [teamsMembers, setTeamsMembers] = React.useState<ITeamMember>(
    {} as ITeamMember,
  )
  const { setMembers } = useMembersContext()

  const createTeamsMembers = (team: ITeam): void => {
    const tempITeamMember: ITeamMember = {}
    tempITeamMember[`${team.name}$${team.id}`] = {}
    // eslint-disable-next-line @typescript-eslint/no-shadow
    team.members?.forEach((member) => {
      tempITeamMember[`${team.name}$${team.id}`][member.id] = {
        id: member.id,
        username: member.username,
        date_joined: member.date_joined,
        date_invited: member.date_invited,
        lists: [],
      }
    })

    team.spaces?.forEach((space) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      space.members?.forEach((member) => {
        space.folders?.forEach((folder) => {
          folder.lists?.forEach((list) => {
            tempITeamMember[`${team.name}$${team.id}`][member.id].lists?.push(
              list,
            )
          })
        })
      })
    })

    setTeamsMembers((prevTeamsMembers) => {
      return { ...prevTeamsMembers, ...tempITeamMember }
    })
  }

  const handleToggle = (event: { target: { checked: boolean } }): void => {
    setToggleList(event.target.checked)
  }

  const updateTeams = React.useCallback((team: ITeam): void => {
    const index = teams.findIndex((t) => t.id === team.id)
    if (index === -1) {
      setTeams((prevTeams) => [...prevTeams, team])
    }
    createTeamsMembers(team)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (!apiKey) {
      navigate('register-key')
    } else {
      getTeams(apiKey, updateTeams).then((response) => {
        if (!response.success) {
          // eslint-disable-next-line no-alert
          alert(response.error)
        }
        updateLoading(false)
      })
    }
    setToggleList(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (teamsMembers) {
      setMembers(teamsMembers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsMembers])

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          overflowX: 'hidden',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {loading ? (
        <div />
      ) : (
        <>
          <SwitchBtn
            handleToggle={handleToggle}
            firstLabel="members"
            secondLabel="teams"
            checked={toggleList}
          />
          {toggleList ? (
            <TeamsTree teams={teams} updateLoading={updateLoading} />
          ) : (
            <MembersTree teams={teamsMembers} updateLoading={updateLoading} />
          )}
        </>
      )}
    </Drawer>
  )
}
