import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DashboardIcon from '@mui/icons-material/Dashboard'
import { login } from '../services/auth/clickupKey'
import { InputText } from '../components/ReactHookForm'

interface IFormInput {
  apiKey: string
}

export default function Login(): React.ReactElement {
  const navigate = useNavigate()
  const methods = useForm<IFormInput>({
    defaultValues: { apiKey: '' },
    resolver: yupResolver(yup.object({ apiKey: yup.string().required() })),
  })

  const onSubmit: SubmitHandler<IFormInput> = ({ apiKey }: IFormInput) => {
    login(apiKey).then(() => navigate('/'))
  }

  return (
    <Box m={12}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12}>
          <Box p={4} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" align="center" gutterBottom color="primary">
            Automita
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={9}
          md={7}
          lg={6}
          xl={4}
          container
          direction="column"
          spacing={1}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <FormProvider {...methods}>
            <Grid item>
              <InputText
                name="apiKey"
                label="Clickup Key"
                type="text"
                placeholder="Enter your Clickup API key"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                onClick={methods.handleSubmit(onSubmit)}
                endIcon={<DashboardIcon />}
              >
                go to Dashboard
              </Button>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
    </Box>
  )
}
