import React from 'react'
import { useNavigate } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import LogoutIcon from '@mui/icons-material/Logout'
import { logout } from '../../../services/auth/clickupKey'
import { StyledAppBar } from './styled'

type Props = {
  open: boolean
  handleDrawerOpen: () => void
}

export default function AppBar({
  open,
  handleDrawerOpen,
}: Props): React.ReactElement {
  const navigate = useNavigate()
  const handleSignout = (): void => {
    logout().then(() => navigate('register-key'))
  }

  return (
    <StyledAppBar position="fixed" open={open}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          Dashboard
        </Typography>
        <Button
          // variant="outlined"
          onClick={handleSignout}
          color="inherit"
          startIcon={<LogoutIcon />}
        >
          register a key
        </Button>
      </Toolbar>
    </StyledAppBar>
  )
}
