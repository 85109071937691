import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AntSwitch } from './styled'

type Props = {
  // eslint-disable-next-line no-unused-vars
  handleToggle: (event: React.ChangeEvent<HTMLInputElement>) => void
  firstLabel: string
  secondLabel: string
  checked: boolean
}

export default function SwitchBtn({
  handleToggle,
  firstLabel,
  secondLabel,
  checked,
}: Props): React.ReactElement {
  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 2 }}>
      <Typography>{firstLabel}</Typography>
      <AntSwitch
        checked={checked}
        inputProps={{ 'aria-label': 'ant design' }}
        onChange={handleToggle}
      />
      <Typography>{secondLabel}</Typography>
    </Stack>
  )
}
