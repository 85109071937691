import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TextField from '@mui/material/TextField'

interface IInputTextProps {
  name: string
  label: string
  type: string
  placeholder: string
}

export default function InputText(props: IInputTextProps): React.ReactElement {
  const { name, label, type, placeholder } = props
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          helperText={fieldState.error ? fieldState.error.message : null}
          error={!!fieldState.error}
          onChange={field.onChange}
          value={field.value}
          label={label}
          type={type}
          placeholder={placeholder}
          fullWidth
          variant="outlined"
          autoFocus
          size="small"
          margin="dense"
        />
      )}
    />
  )
}
