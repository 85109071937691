import React from 'react'
import Gantt from 'frappe-gantt'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Paper from '@mui/material/Paper'
import { ChartTask } from '../../utils/datatypes'
import TaskDialog from '../TaskDialog/Dialog'

type Props = {
  tasks: ChartTask[] | undefined
}
type viewMode = 'Quarter Day' | 'Half Day' | 'Day' | 'Week' | 'Month'
const viewModeOptions: viewMode[] = [
  'Quarter Day',
  'Half Day',
  'Day',
  'Week',
  'Month',
]

export default function Chart({ tasks }: Props): React.ReactElement {
  const [viewMode, setViewMode] = React.useState<viewMode>('Week')
  const [open, setOpen] = React.useState(false)
  const [task, setTask] = React.useState<ChartTask | undefined>()

  const handleClose = (): void => {
    setOpen(false)
    setTask(undefined)
  }

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    value: viewMode,
  ): void => {
    if (value) {
      setViewMode(value)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderChart = (tasksList: ChartTask[]): any => {
    const chart = new Gantt('#gantt', tasksList, {
      header_height: 50,
      column_width: 100,
      step: 24,
      view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
      bar_height: 20,
      bar_corner_radius: 3,
      arrow_curve: 5,
      padding: 18,
      view_mode: 'Week', // selectedViewMode,
      date_format: 'YYYY-MM-DD',
      // on click event capture only double click events
      on_click: (_task) => {
        if (tasks) {
          setTask(tasks.find((t) => t.id === _task.id))
          setOpen(true)
        }
      },
    })
    return chart
  }

  React.useEffect(() => {
    if (tasks?.length) {
      const gantt = renderChart(tasks)
      if (gantt) gantt.change_view_mode(viewMode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMode, tasks])

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={viewMode}
        exclusive
        onChange={handleChange}
      >
        {viewModeOptions.map((mode) => (
          <ToggleButton key={mode} value={mode}>
            {mode}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Paper elevation={6} square>
        {tasks && <div id="gantt" />}
      </Paper>

      <TaskDialog open={open} task={task} handleClose={handleClose} />
    </>
  )
}
