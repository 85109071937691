import React from 'react'
import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import AppBar from '../AppBar/AppBar'
import NavList from '../NavList/NavList'
import { Main } from './styled'
import { DrawerHeader } from '../NavList/styled'

export default function Layout(): React.ReactElement {
  const [open, setOpen] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  const updateLoading = (state: boolean): void => {
    setLoading(state)
  }

  return (
    <Box sx={{ display: 'flex', overflowX: 'hidden' }}>
      <AppBar open={open} handleDrawerOpen={handleDrawerOpen} />
      <NavList
        open={open}
        handleDrawerClose={handleDrawerClose}
        updateLoading={updateLoading}
        loading={loading}
      />
      <Main open={open}>
        <DrawerHeader />
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Outlet context={{ setLoading }} />
      </Main>
    </Box>
  )
}

Layout.defaultProps = {
  children: null,
}
