import * as React from 'react'
import { styled } from '@mui/material/styles'
import Avatar from '@mui/material/Avatar'
import Chip from '@mui/material/Chip'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

interface IColorProps {
  color?: string
}

interface IBgColorProps {
  bgColor?: string
}

export const ColoredAvatar = styled(Avatar)(({ color }: IColorProps) => ({
  background: color,
}))

export const ColoredChip = styled(Chip)(({ bgColor }: IBgColorProps) => ({
  background: bgColor,
  marginTop: '5px',
  marginRight: '5px',
  borderRadius: '20px',
  color: 'white',
}))

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction="up" ref={ref} {...props} />
})
