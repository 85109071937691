import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/common/Layout/Layout'
import { Login, Dashboard, List, Member } from './pages'
import './chart.css'
import { MembersProvider } from './context/global-members-context'

function App(): React.ReactElement {
  return (
    <MembersProvider>
      <Routes>
        <Route path="register-key" element={<Login />} />
        <Route path="/" element={<Layout />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="list/:id" element={<List />} />
          <Route path="team/:teamId/member/:memberId" element={<Member />} />
        </Route>
      </Routes>
    </MembersProvider>
  )
}

export default App
